import React, {useEffect, useState, useCallback} from 'react';
import {Box, VStack, Text, Button, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton} from '@chakra-ui/react';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useTranslation} from 'react-i18next';
import {useMe} from '@/haligator-factory';
import {useCommand} from '@synako/haligator';

// parametres pour la configuration de l'avertissement de session
const CONFIG = {
  default: {
    // intervalle de verification de la session
    checkInterval: 30 * 60 * 1000, // 30 minutes en millisecondes
    warningTime: 30 * 60,          // 30 minutes en secondes
    position: 'bottom' as const,
    buttonColor: 'orange'
  },
  backoffice: {
    checkInterval: 10 * 60 * 1000, // 10 minutes
    warningTime: 10 * 60,          // 10 minutes
    position: 'top' as const,
    buttonColor: 'blue'
  }
};

interface SessionWarningProps {
  isBackoffice?: boolean;
}

export const SessionWarning: React.FC<SessionWarningProps> = ({isBackoffice = false}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [expiresIn, setExpiresIn] = useState<number>(0);
  const [countdown, setCountdown] = useState<number>(0);
  const textToast = useSimpleTextToast();
  const {t} = useTranslation('common');
  const me = useMe();
  const config = isBackoffice ? CONFIG.backoffice : CONFIG.default;

  // logout en fin de session
  const redirectToLogout = useCallback(() => {
    window.location.href = isBackoffice ? '/authn/microsoft/logout' : '/authn/logout';
  }, [isBackoffice]);

  const {submit: submitExtendSession, isSubmitting} = useCommand(me, 'c:extend-session', {
    onSuccess: async () => {
      await me.refresh();
      setShowWarning(false);
      textToast(t('toast.prolonge_session'));
    },
    onError: (error) => {
      console.error('Erreur lors de la prolongation de la session:', error);
      textToast(t('toast.prolongement_session_impossible'), {icon: 'alert'});
      redirectToLogout();
    }
  });

  // Effet pour gérer le décompte dans le message d'alerte
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => {
        const newValue = Math.max(0, prev - 1);
        return newValue;
      });
    }, 1000);

    // Initialise le décompte quand expiresIn change
    setCountdown(expiresIn);

    return () => {clearInterval(timer);};
  }, [expiresIn]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    // Vérifie régulièrement si la session est bientôt expirée
    const checkSessionWarning = async () => {
      try {
        const data = await me.refresh();
        const sessionInfo = data.data.session;

        if (sessionInfo) {
          if (sessionInfo.status === 'expired') {
            redirectToLogout();
            return;
          }

          setExpiresIn(sessionInfo.expiresIn ?? 0);

          // Nettoyer le timeout existant
          if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = undefined;
          }

          // Afficher l'avertissement avant expiration
          const shouldWarn = (sessionInfo.expiresIn ?? 0) <= config.warningTime;
          setShowWarning(shouldWarn);

          // Programmer la déconnexion à l'expiration
          if ((sessionInfo.expiresIn ?? 0) <= 0) {
            redirectToLogout();
          } else if (shouldWarn) {
            timeoutId = setTimeout(() => {
              redirectToLogout();
            }, (sessionInfo.expiresIn ?? 0) * 1000);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de la session:', error);
        redirectToLogout();
      }
    };

    const intervalId = setInterval(checkSessionWarning, config.checkInterval);
    checkSessionWarning();

    return () => {
      clearInterval(intervalId);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [redirectToLogout, me, config.checkInterval, config.warningTime]);

  // Prolonge la session
  const handleExtendSession = () => {submitExtendSession({});};

  if (!showWarning) return null;

  // Utilisation de countdown pour un affichage plus précis actualisé
  const minutes = Math.ceil(countdown / 60);

  return (
    <Box position="fixed" right="4" zIndex="toast" maxW="sm" width="full" {...(config.position === 'top' ? {top: '4'} : {bottom: '4'})}>
      <Alert status="warning" variant="solid" borderRadius="md" boxShadow="lg">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>{t('session.session_bientot_expiree')}</AlertTitle>
          <AlertDescription display="block">
            <VStack align="stretch" spacing={3} mt={2}>
              <Text>
                {t('session.delai_expiration', {minutes})}
              </Text>
              <Button
                colorScheme={config.buttonColor}
                size="sm"
                onClick={handleExtendSession}
                isLoading={isSubmitting}
                loadingText={t('session.prolongation')}
              >
                {t('session.prolonger_session')}
              </Button>
            </VStack>
          </AlertDescription>
        </Box>
        <CloseButton
          position="absolute"
          right="2"
          top="2"
          onClick={() => {setShowWarning(false);}}
        />
      </Alert>
    </Box>
  );
};
