import {FollowPromiseOne, State} from '@synako/halitrek';
import {ReactElement, useEffect, useState} from 'react';
import {ClientDesc, LivraisonDesc} from 'api-types/ffconnect-hal';

//Gestion des erreurs et écran de chargement pour le wrapper principal de l'application
export const useClientAppDisplay = (
  Outlet: (prochaineLivraison: State<LivraisonDesc>) => ReactElement,
  ChargementCompte: (idMessage:string)=>ReactElement, 
  CompteBloque: () => ReactElement,
  client: FollowPromiseOne<ClientDesc>,
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(undefined);
  const [prochaineLivraison, setProchaineLivraison] = useState<State<LivraisonDesc> | undefined>(undefined);
  // Ici c'est juste un premier chargement et donc on ne souhaite surtout pas avoir les refresh globaux à l'appli
  useEffect(() => {
    Promise.all([
      client.follow('livraisons_possibles').followAll('items').getAll(),
      //Cadencier avec les items
      client.follow('cadencier').get(),
      client.follow('cadencier').followAll('selection').getAll(),
    ]).then(([livraisons]) => {
      if (livraisons.length) {
        setProchaineLivraison(livraisons[0]);
      } else {
        setError(new Error('Pas de prochaine livraison'));
      }
    }).catch(setError)
      .finally(() => {setLoading(false);});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return CompteBloque();
  }
  if (loading || !prochaineLivraison) {
    return ChargementCompte('chargement_catalogue');
  }
  return Outlet(prochaineLivraison);
};
