import {useCallback, useEffect, useRef, useMemo, useState} from 'react';
import {Box, Input, InputGroup, InputRightAddon, VStack} from '@chakra-ui/react';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {SearchIcon} from '../../svg/SearchIcon';
import {SEARCH_MIN_CHARS, useSearchClient} from 'ff-common';
import {Results} from '@orama/orama';
import {HitItemCatalogue} from 'api-types/ffconnect';
import {useTranslation} from 'react-i18next';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {SearchResultItemComponent} from './SearchResultItemComponent';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {debounce} from 'lodash-es';

interface SearchModalProps {
  initialTerm: string;
  onTermSubmit: (term: string) => void;
  itemLink: (code: string) => string;
  handleCartIconClick: (code: string, multiple: number | undefined) => void;
  handlePrecommandeAction: (itemState: State<ItemCatalogueDesc>) => void;
}

export const SearchModal = NiceModal.create<SearchModalProps>(({
  initialTerm,
  onTermSubmit,
  itemLink,
  handleCartIconClick,
  handlePrecommandeAction,
}) => {
  const modal = useModal();
  const {t} = useTranslation('common');
  const inputRef = useRef<HTMLInputElement>(null);
  const [term, setTerm] = useState(initialTerm);
  const search = useSearchClient();
  const [itemResults, setItemResults] = useState<Results<HitItemCatalogue>>();

  useEffect(() => {
    if (!modal.visible) return;
    // Petit délai pour laisser la modale s'ouvrir complètement
    const timeoutId = setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, 10);

    return () => {clearTimeout(timeoutId);};
  }, [modal.visible]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchTerm: string) => {
        if (search.indexIsBuilt && searchTerm.length >= SEARCH_MIN_CHARS) {
          const results = await search.searchItems({q: searchTerm});
          setItemResults(results);
        } else {
          setItemResults(undefined);
        }
      }, 300),
    [search]
  );

  const handleTermChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newTerm = event.target.value;
    setTerm(newTerm);
    debouncedSearch(newTerm);
  }, [debouncedSearch]);

  const handleTermClick = useCallback(() => {
    if (term) {
      onTermSubmit(term);
    }
    modal.hide();
  }, [term, onTermSubmit, modal]);

  // Touche entrée
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleTermClick();
    }
  }, [handleTermClick]);

  const contenuBarreModale = useMemo(() => (
    <Box>
      <InputGroup bg='white' borderTopRadius='8px' opacity={search.indexIsBuilt ? 1 : 0.2}>
        <Input
          ref={inputRef}
          placeholder={search.indexIsBuilt ? t('catalogue.chercher_produit', {min_lettres: SEARCH_MIN_CHARS}) : t('catalogue.chargement')}
          onChange={handleTermChange}
          value={term}
          onKeyDown={handleKeyDown}
          isDisabled={!search.indexIsBuilt}
          _focus={{
            boxShadow: 'none',
            borderColor: 'gray.200',
          }}
          height="40px"
          cursor='pointer'
        />
        <InputRightAddon pointerEvents="auto" cursor="pointer" onClick={handleTermClick}>
          <SearchIcon />
        </InputRightAddon>
      </InputGroup>
      {itemResults && term.length >= SEARCH_MIN_CHARS && (
        <Box 
          bg='white' 
          w='100%' 
          fontSize='sm' 
          fontWeight='400' 
          maxHeight={{base:'calc(100vh - 120px)', md:'400px'}} 
          overflowY="auto" 
          mt={2} 
          sx={{
            '&::-webkit-scrollbar': {
              width: '0px',
              background: 'transparent'
            },
            '&': {
              scrollbarWidth: 'none',
              msOverflowStyle: 'none'
            },
          }}
        >
          {itemResults.hits.slice(0, 15).map(item => (
            <SearchResultItemComponent
              key={item.id}
              item={item}
              itemLink={itemLink}
              handleProductClick={modal.hide}
              handleCartIconClick={handleCartIconClick}
              handlePrecommandeAction={handlePrecommandeAction}
            />
          ))}
        </Box>
      )}
    </Box>
  ), [search.indexIsBuilt, term, handleTermChange, handleKeyDown, itemResults, itemLink, modal.hide, handleCartIconClick, handlePrecommandeAction, handleTermClick, t]);

  return (
    <ModaleLayout
      visible={modal.visible}
      hide={modal.hide}
      size_desktop="xl"
      drawerPlacement="top"
      drawerSize="full"
      contentStyle={{
        top: '0',
        h: {base: '100vh', md: 'auto'},
        mb: '0',
      }}
      closeButtonStyle={{
        marginTop: {base: '5px', md: '6'},
        marginRight: {base: 'auto', md: '1'},
        height: '40px',
      }}
    >
      <Box pr={{base: '20px', md: '0'}} width="100%">
        <VStack spacing={4} align="stretch">
          {contenuBarreModale}
        </VStack>
      </Box>
    </ModaleLayout>
  );
});
