import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {RepeatIcon} from '@chakra-ui/icons';
import {Button, Link} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

export const ChangerClientButton = () => {
  const {t} = useTranslation('common');
  const {buildAssistanceRoute} = useIsAssistanceMode();
  return (
    <Button
      as={Link}
      border='1px'
      borderColor='blue.main'
      bg='white'
      color='blue.main'
      px='8' 
      leftIcon={<RepeatIcon color="blue.main" />}
      href={buildAssistanceRoute(routes.client.clientSelect, undefined)} 
    >
      {t('menu.changer_de_client')}
    </Button>
  );
};
