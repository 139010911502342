import {CartIcon} from '@/components/svg/CartIcon';
import ColdIcon from '@/components/svg/ColdIcon';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {useClient} from '@/haligator-factory';
import {Box, HStack, Stack, Text, VStack} from '@chakra-ui/layout';
import {Image} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {Result} from '@orama/orama';
import {useHalState} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {HitItemCatalogue} from 'api-types/ffconnect';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

interface SearchResultItemComponentProps {
  item: Result<HitItemCatalogue>;
  itemLink: (code: string) => string;
  handleProductClick: () => void;
  handleCartIconClick: (code: string, multiple: number | undefined) => void;
  handlePrecommandeAction: (itemState: State<ItemCatalogueDesc>) => void;
}

export const SearchResultItemComponent: React.FC<SearchResultItemComponentProps> = ({item, itemLink, handleProductClick, handleCartIconClick, handlePrecommandeAction}) => {
  const client = useClient();
  const itemNav = useMemo(() => client.follow('go:item_catalogue', {code_article: item.document.code}), [client, item.document.code]);
  const itemState = useHalState(itemNav).state;

  const handleClick = useCallback(() => {
    handleProductClick();
    NiceModal.remove(CatalogueModale);
  }, [handleProductClick]);

  // gére le cas d'un article en precommande ou non
  const handleIconClick = () => {
    if (itemState?.data.tolede.precommande) {
      handlePrecommandeAction(itemState);
    } else {
      handleCartIconClick(item.document.code, item.document.multiple_de_commande_client);
    }
  };

  return (
    <HStack key={item.id} w='100%' justifyContent='space-between' spacing={0}>
      <Link to={itemLink(item.document.code)} style={{width: 'calc(100% - 60px)', display: 'block'}}>
        <SearchResultItem
          item={item}
          onProductClick={handleClick}
        />
      </Link>
      <Box cursor="pointer" width='60px' height='100%' display="flex" justifyContent="center" alignItems="center" _hover={{transform: 'scale(1.2)'}} transition="transform 0.2s ease-in-out" onClick={handleIconClick}>
        <CartIcon color='gray.dark' />
      </Box>
    </HStack>
  );
};

const SearchResultItem: React.FC<{item: Result<HitItemCatalogue>, onProductClick: () => void;}> = ({item, onProductClick}) => {
  const itemVignette = imageOptimisee(item.document.vignette_url, 40);
  const {t} = useTranslation('common');

  return (
    <Stack direction="row" px={4} py={2} cursor="pointer" _hover={{bg: 'gray.100'}} alignItems='center' spacing={3} onClick={onProductClick} position='relative'>
      <VStack flexShrink={0} boxSize="40px">
        <Image src={itemVignette} alt={item.document.libelle_commercial} maxHeight='40px' maxWidth='40px'/>
      </VStack>
      {item.document.temperature_livraison === 'negatif' && <Box position='absolute' bottom='3' left='2'><ColdIcon boxSize='13px' /></Box>}
      <VStack alignItems='start' spacing={0} flex={1}>
        <HStack>
          <Text fontSize='2xs' fontStyle='initial'>{item.document.code}</Text>
          {item.document.nature_du_prix === 'promo' && <Text fontSize='2xs' fontStyle='initial' color='red.500'>{t('global.promotion')}</Text>}
          {item.document.est_cadencier && <Text fontSize='2xs' fontStyle='initial' color='blue.main'>{t('global.cadencier')}</Text>}
        </HStack>
        <Text fontSize='xs' fontWeight='700'>{item.document.libelle_commercial}</Text>
        <Text fontSize='xs'>{item.document.marque}</Text>
      </VStack>
    </Stack>
  );
};
