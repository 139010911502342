import {BoxProps, Input, InputGroup, InputLeftElement, Box, Button} from '@chakra-ui/react';
import {SearchIcon} from '../../svg/SearchIcon';
import {SEARCH_MIN_CHARS, useSearchClient} from 'ff-common';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {routes} from 'ff-common';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useTranslation} from 'react-i18next';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useLivraisonsPossiblesContext} from 'ff-common';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import {useAjouterAuPanier} from '@/hooks/livraison/use-ajouter-au-panier';
import NiceModal from '@ebay/nice-modal-react';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';
import {SearchModal} from './SearchModal';

export const Searchbar: React.FC<{props?: BoxProps, onModalClose?: () => void;}> = ({props, onModalClose}) => {
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const [searchParams] = useSearchParams();
  const [term, setTerm] = useState(searchParams.get('q') ?? '');
  const navigate = useNavigate();
  const {t} = useTranslation('common');
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const textToast = useSimpleTextToast();
  const search = useSearchClient();

  useEffect(() => {
    setTerm(searchParams.get('q') ?? '');
  }, [searchParams]);

  const handleTermSubmit = useCallback((newTerm: string) => {
    setTerm(newTerm);
    const rechercheUrl = buildAssistanceRoute(routes.client.recherche, {}, {q: newTerm});
    navigate(rechercheUrl);
    if (onModalClose) {
      onModalClose();
    }
  }, [buildAssistanceRoute, navigate, onModalClose]);

  const itemLink = useCallback((code_article: string) => {
    return buildAssistanceRoute(routes.client.produit, {code_article}, {from: 'recherche', query: encodeURI(`?q=${term}`)});
  }, [term, buildAssistanceRoute]);

  const {submit: AjouterAction} = useAjouterAuPanier(livraisonSelectionnee.data.id, textToast);

  const AjouterAuPanier = useCallback(async (code_article: string, quantite: number) => {
    AjouterAction({code_article, quantite, commande_en: 'unitaire'});
  }, [AjouterAction]);

  // pour un article qui n'est pas en precommande
  const handleCartIconClick = useCallback((code: string, multiple_de_commande_client: number | undefined) => {
    AjouterAuPanier(code, multiple_de_commande_client ?? 1);
    navigate(itemLink(code));
    NiceModal.hide(SearchModal);
    NiceModal.remove(CatalogueModale);
  }, [AjouterAuPanier, navigate, itemLink]);

  // pour un article qui est en precommande
  const handlePrecommandeAction = useCallback((itemState: State<ItemCatalogueDesc>) => {
    textToast(t('toast.produit_precommande'));
    navigate(itemLink(itemState.data.tolede.code_article));
    NiceModal.hide(SearchModal);
  }, [navigate, itemLink, textToast, t]);

  const handleOpen = useCallback(() => {
    NiceModal.show(SearchModal, {
      initialTerm: term,
      onTermSubmit: handleTermSubmit,
      itemLink,
      handleCartIconClick,
      handlePrecommandeAction
    });
  }, [term, handleTermSubmit, itemLink, handleCartIconClick, handlePrecommandeAction]);

  const breakpoint = useContext(BreakPointContext);

  return (
    <Box position="relative" width={{base:'100%', md:'30px', lg:'100%'}} {...props} onClick={handleOpen}>
      {breakpoint?.breakpoint!=='md' ? (
        <InputGroup zIndex={1003} bg='white' borderTopRadius='8px' opacity={search.indexIsBuilt ? 1 : 0.2}>
          <InputLeftElement pointerEvents="auto" cursor="pointer">
            <SearchIcon />
          </InputLeftElement>
          <Input
            placeholder={search.indexIsBuilt ? `Chercher un produit : ${SEARCH_MIN_CHARS} lettres minimum` : 'Nous chargeons votre catalogue ...'}
            isDisabled={!search.indexIsBuilt}
            tabIndex={-1}
            readOnly
            cursor='pointer'
          />
        </InputGroup>
      ) : (
        <Button variant='ghost'>
          <SearchIcon />
        </Button>
      )}
    </Box>
  );
};
