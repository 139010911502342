import {Box, Button, Checkbox, Divider, HStack, Spacer, Text, VStack} from '@chakra-ui/react';
import {HitItemCatalogue, TemperatureLivraison} from 'api-types/ffconnect';
import {useTranslation} from 'react-i18next';
import {PromoIcon} from '@/components/svg/PromoIcon';
import {Results} from '@orama/orama';
import {useEffect, useState, useMemo} from 'react';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {BlueButton} from '@/components/Button/BlueButton';
import {HookUseTypedFilters} from '@synako/typed-path';
import {FiltresItemCatalogue} from 'ff-common/src/hooks/search/use-search';

interface CatalogFilterModaleProps {
  labels: [string, number][] | undefined;
  paysOrigine: [string, number][] | undefined;
  promoCount: number | undefined;
  labelsCount: number | undefined;
  paysOrigineCount: number | undefined;
  hasPromo: boolean;
  itemResults?: Results<HitItemCatalogue>;
  filtersHook: HookUseTypedFilters<FiltresItemCatalogue>;
  fraisCount: number,
  surgeleCount: number,
}

// Le code a été commenté pour les filtres sur les pays d'origine en attendant de savoir ce que l'on fait avec les drapeaux

//Uniquement utilisé en mobile
export const CatalogFilterModale = NiceModal.create<CatalogFilterModaleProps>(
  ({labels,
    // paysOrigine,
    promoCount,
    labelsCount,
    // paysOrigineCount,
    hasPromo,
    itemResults:_itemResults,
    filtersHook,
    fraisCount,
    surgeleCount
  }) => {
    const {t} = useTranslation('common');
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
    // const [selectedPays, setSelectedPays] = useState<PaysEnum[]>([]);
    const [selectedTemperature, setSelectedTemperature] = useState<string[]>([]);
    const [isPromoSelected, setIsPromoSelected] = useState(false);
    const {params: filters, changeMultiple, change} = filtersHook;

    // Vérifier si des filtres sont actifs
    const hasActiveFilters = useMemo(() => {
      return (
        isPromoSelected ||
    selectedLabels.length > 0 ||
    selectedTemperature.length > 0
      );
    }, [isPromoSelected, selectedLabels, selectedTemperature]);

    // pour mettre à jour les checks lorsque les filtres changent
    useEffect(() => {
      setSelectedLabels(filters.labels ?? []);
      // setSelectedPays(filters.pays_d_origine ?? []);
      setSelectedTemperature(filters.temperature_livraison ? [filters.temperature_livraison] : []);
      setIsPromoSelected(filters.nature_du_prix === 'promo');
    }, [filters.labels, filters.temperature_livraison,
      // filters.pays_d_origine
      filters.nature_du_prix
    ]);

    const handleRemoveSelected = async () => {
      if (!hasActiveFilters) return;

      // Réinitialise les états locaux
      setSelectedLabels([]);
      setSelectedTemperature([]);
      setIsPromoSelected(false);

      // Réinitialise les filtres en gardant les filtres essentiels
      const currentFilters = {...filters};
      const resetFilters = {
        ...currentFilters,
        nature_du_prix: undefined,
        labels: undefined,
        temperature_livraison: undefined,
      };

      // Mise à jour des filtres
      changeMultiple(resetFilters);
    };

    // ordre alphabétique ou de pertinence des produits
    const handleSortChange = () => {
      const newSortBy = filters.sortBy === 'alphabetique' ? undefined : 'alphabetique';
      change('sortBy', newSortBy);
    };

    const modal = useModal();

    return (
      <ModaleLayout visible={modal.visible} hide={modal.remove}>
        <VStack alignItems="stretch" justifyContent="space-between" spacing='0'>
          <HStack justifyContent='start'>
            <Text fontWeight='700'>{t('catalogue.filtre.filtrer')}</Text>
            <Button
              variant="unstyled"
              ml='30px'
              fontSize='sm'
              color={hasActiveFilters ? 'gray.dark' : 'gray.light'}
              textDecoration='underline'
              onClick={handleRemoveSelected}
              isDisabled={!hasActiveFilters}
            >
              {t('catalogue.filtre.tout_effacer')}
            </Button>
          </HStack>
          <Divider />
          <>
            <HStack height='38px' p='7px 11px 7px 11px' fontWeight="400" color="blue.main">
              <Checkbox
                borderColor='gray.light'
                size='md'
                fontWeight='600'
                isChecked={isPromoSelected}
                isDisabled={!hasPromo}
                onChange={(event) => {
                  const newValue = event.target.checked;
                  setIsPromoSelected(newValue);
                  change('nature_du_prix', newValue ? 'promo' : undefined);
                }}
              >
                {t('catalogue.filtre.promotions')} ({promoCount})
              </Checkbox>
              <PromoIcon isDisabled={!hasPromo} />
            </HStack>
            <Divider />
          </>
          <Box height='38px' p='7px 11px 7px 11px' fontWeight="400" display="flex" alignItems="center" justifyContent="space-between">
            <Text fontSize='sm' fontWeight='700'>Trier par :</Text>
            <HStack spacing={4}>
              <Button
                variant={filters.sortBy !== 'alphabetique' ? 'solid' : 'ghost'}
                colorScheme={filters.sortBy !== 'alphabetique' ? 'blue' : 'gray'}
                size='xs'
                onClick={handleSortChange}
              >
                {t('catalogue.filtre.pertinence')}
              </Button>
              <Button
                variant={filters.sortBy === 'alphabetique' ? 'solid' : 'ghost'}
                colorScheme={filters.sortBy === 'alphabetique' ? 'blue' : 'gray'}
                size='xs'
                onClick={handleSortChange}
              >
                {t('catalogue.filtre.a-z')}
              </Button>
            </HStack>
          </Box>
          <Divider />
          <Box mt='10px' mb='10px' ml='10px'>
            <Text fontSize='sm' fontWeight='700' mb='10px' color={(labelsCount ?? 0) <= 0 ? 'gray.400' : 'black'}>
              {t('catalogue.filtre.labels')} ({labelsCount})
            </Text>
            <VStack alignItems="stretch" maxHeight="120px" overflowY="auto">
              {labels?.map((label) => (
                <HStack key={label[0]} alignItems="center">
                  <Box>
                    <Text fontSize='sm'>{label[0]}<Text as="span" color='gray.dark' pl='10px' fontSize='xs'>({label[1]})</Text></Text>
                  </Box>
                  <Spacer />
                  <Checkbox
                    borderColor='gray.light'
                    size='lg'
                    mr='12px'
                    isChecked={selectedLabels.includes(label[0])}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      const newLabels = isChecked
                        ? [...selectedLabels, label[0]]
                        : selectedLabels.filter(l => l !== label[0]);
                      setSelectedLabels(newLabels);
                      change('labels', newLabels);
                    }}
                  />
                </HStack>
              ))}
            </VStack>
          </Box>
          <Divider />
          {/* <Box ml='10px'>
            <Text fontSize='sm' fontWeight='700' mb='10px' mt='10px' color={(paysOrigineCount ?? 0) <= 0 ? 'gray.400' : 'black'}>{t('catalogue.filtre.origine')} ({paysOrigineCount})</Text>
            <VStack alignItems="stretch" maxHeight="120px" overflowY="auto">
              {paysOrigine?.map((pays) => (
                <HStack key={pays[0]} alignItems="center">
                  <Box>
                    <Text fontSize='sm'>{t(`countries.${pays[0]}`)}<Text as="span" color='gray.dark' pl='10px' fontSize='xs'>({pays[1]})</Text></Text>
                  </Box>
                  <Spacer />
                  <Checkbox
                    borderColor='gray.light'
                    size='lg'
                    mr='12px'
                    isChecked={selectedPays.includes(pays[0] as PaysEnum)}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      const newPays = isChecked
                        ? [...selectedPays, pays[0] as PaysEnum]
                        : selectedPays.filter(p => p !== pays[0]);
                      setSelectedPays(newPays);
                      changeFilter('pays_d_origine', newPays);
                    }}
                  />
                </HStack>
              ))}
            </VStack>
          </Box> */}
          <Divider />
          <Box mt='10px' mb='10px' ml='10px'>
            <Text fontSize='sm' fontWeight='700' mb='10px' color={(fraisCount + surgeleCount) <= 0 ? 'gray.400' : 'black'}>
              {t('catalogue.filtre.non_surgele_surgele')} ({fraisCount + surgeleCount})
            </Text>
            <VStack alignItems="stretch">
              <HStack alignItems="center">
                <Text fontSize='sm'>{t('catalogue.filtre.non_surgele')}<Text as="span" color='gray.dark' pl='10px' fontSize='xs'>({fraisCount})</Text></Text>
                <Spacer />
                <Checkbox
                  borderColor='gray.light'
                  size='lg'
                  mr='12px'
                  isChecked={selectedTemperature.includes('positif')}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    const nouvelleTemperature = isChecked ? ['positif'] : [];
                    setSelectedTemperature(nouvelleTemperature);
                    change('temperature_livraison', (nouvelleTemperature[0] || undefined) as TemperatureLivraison);
                  }}
                />
              </HStack>
              <HStack alignItems="center">
                <Text fontSize='sm'>{t('catalogue.filtre.surgele')}<Text as="span" color='gray.dark' pl='10px' fontSize='xs'>({surgeleCount})</Text></Text>
                <Spacer />
                <Checkbox
                  borderColor='gray.light'
                  size='lg'
                  mr='12px'
                  isChecked={selectedTemperature.includes('negatif')}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    const nouvelleTemperature = isChecked ? ['negatif'] : [];
                    setSelectedTemperature(nouvelleTemperature);
                    change('temperature_livraison', (nouvelleTemperature[0] || undefined) as TemperatureLivraison);
                  }}
                />
              </HStack>
            </VStack>
          </Box>
          <BlueButton
            onClick={modal.remove}
            texte={t('catalogue.filtre.afficher')}
          />
        </VStack>
      </ModaleLayout>
    );
  });
