import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {Image} from '@chakra-ui/react';

export const HeaderImage = () => (
  <Image
    src={imageOptimisee(`${import.meta.env['VITE_MEDIA_URL']}/header/header-band-blue.png`, 1200)}
    alt='Header-img'
    w='100vw'
    h={{base:'50px', md:'40px'}}
    objectFit='cover'
  />
);
